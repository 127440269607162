
import { computed, defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { useLazyQuery } from "@vue/apollo-composable";
import LoginInteractor from "@interactors/login/Login.interactor";
import Container from "typedi";
import Services from "@services/Services";
import gql from "graphql-tag";
import UserService from "@services/user/User.service";
import router, { validateUserSetup } from "@frameworks/vue/router/clean";
import { SITE_USER_ROLE_LIST } from "@services/user/constants/SITE_USER_ROLES";

type LoginFormValues = {
    email: string;
    password: string;
};

type PostLoginQueryResponse = {
    me: {
        id: string;
        guides: {
            id: string;
            steps: {
                id: string;
                isCompleted: boolean;
            }[];
        }[];
        site: {
            whereSell: string | null;
            whichCarriers: string | null;
            featuresLooking: string | null;
            planningProducts: number | null;
            planningMonthlyOrders: number | null;
        };
    };
};

const postLoginQuery = gql`
    query PostLoginMe {
        me {
            id
            guides {
                id
                steps {
                    id
                    isCompleted
                }
            }
            site {
                id
                whereSell
                whichCarriers
                featuresLooking
                planningProducts
                planningMonthlyOrders
            }
        }
    }
`;

export default defineComponent({
    name: "account-login",
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    methods: {
        async onLoginSubmit(values: LoginFormValues) {
            // Activate indicator
            this.submitButton?.setAttribute("data-kt-indicator", "on");

            try {
                const { integration } = await Container.get(
                    LoginInteractor
                ).login(values.email, values.password);

                this.handlePostLogin(integration);
            } catch (error) {
                this.submitButton?.removeAttribute("data-kt-indicator");
            }
        },
        async handlePostLogin(
            integration: "WIX" | "SHOPIFY" | "BIGCOMMERCE" | undefined
        ) {
            const { onResult, onError, load } = useLazyQuery<
                PostLoginQueryResponse,
                void
            >(postLoginQuery, undefined, {
                fetchPolicy: "cache-and-network",
            });

            onError(() => {
                if (integration) {
                    this.handleIntegrationLogin();
                    return;
                }

                this.handleRegularLogin();
            });
            onResult((result) => {
                if (integration) {
                    this.handleIntegrationLogin();
                    return;
                }

                this.handleRegularLogin(result.data);
            });
            load();
        },
        handleIntegrationLogin() {
            router.push("/settings/integrations");
            return;
        },
        handleRegularLogin(data?: PostLoginQueryResponse) {
            if (!data) {
                router.push("/home/personal-homepage");
                return;
            }

            const userSetupRedirect = validateUserSetup(data.me.site);

            if (userSetupRedirect) {
                router.push(userSetupRedirect);
                return;
            }

            const { me } = data;
            const user = Container.get(UserService).getUser();
            const roleGuides =
                Services.getStaticData().getOnboardingRolesMapping();
            const roleEnum = user.getRole().get();
            const role = SITE_USER_ROLE_LIST[roleEnum];
            const firstTimeGuideByRole =
                roleGuides[role].first_time_setup ?? [];
            const advancedGuideByRole = roleGuides[role].advanced_guide ?? [];
            const firstTimeGuide = me.guides.find(({ id }) => id === "g1");
            const advancedGuide = me.guides.find(({ id }) => id === "g2");

            const completedFirstTimeSteps =
                firstTimeGuide?.steps
                    .filter(({ isCompleted }) => isCompleted)
                    .map(({ id }) => id) ?? [];
            const completedAdvancedSteps =
                advancedGuide?.steps
                    .filter(({ isCompleted }) => isCompleted)
                    .map(({ id }) => id) ?? [];
            const firstTimeGuideCompleted = firstTimeGuideByRole?.every(
                (entry) => completedFirstTimeSteps.includes(entry)
            );
            const advancedGuideCompleted = advancedGuideByRole?.every((entry) =>
                completedAdvancedSteps.includes(entry)
            );

            if (!firstTimeGuideCompleted && !advancedGuideCompleted) {
                router.push("/onboarding/first-time-setup");
                return;
            }

            if (!advancedGuideCompleted) {
                router.push("/onboarding/advanced-guide");
                return;
            }

            router.push("/home/personal-homepage");
        },
    },
    setup() {
        const store = useStore();
        const submitButton = ref<HTMLElement | null>(null);
        const flagService = Services.getFeatureFlags();
        const hasV3SignupFlag = flagService.hasFlag("v2-registration");
        const signupLink = hasV3SignupFlag ? "/account/create" : "/auth/signup";
        const loginSchema = Yup.object().shape({
            email: Yup.string().email().required().label("Email"),
            password: Yup.string().min(4).required().label("Password"),
        });

        //Form submit function
        const onSubmitLogin = async function (values) {
            if (submitButton.value) {
                // Activate indicator
                submitButton.value.setAttribute("data-kt-indicator", "on");
            }

            // Call login interactor
            try {
                await Container.get(LoginInteractor).login(
                    values.email,
                    values.password
                );
            } catch (error) {
                submitButton.value?.removeAttribute("data-kt-indicator");
            }
        };

        const originUrl = window.location.origin;
        const logo = "/media/logos/new-login-logo.svg";

        return {
            messagesError: computed(() => store.getters.getErrors),
            logo,
            originUrl,
            onSubmitLogin,
            loginSchema,
            submitButton,
            signupLink,
        };
    },
});
